<script setup>
const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  alt: {
    type: String,
    default: "",
  },
});

const attrs = useAttrs();

const imageHtmlEl = ref(null);
const loading = ref(false);
const isVisible = ref(false);

let observer = null;

function createIntersectionObserver() {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isVisible.value = true;
          loadImage();
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: "50px", // Начинать загрузку заранее
      threshold: 0.1,
    }
  );
}

function loadImage() {
  if (props.src) {
    loading.value = true;
    const img = new Image();
    img.onload = () => {
      if (imageHtmlEl.value) {
        imageHtmlEl.value.src = props.src;
        loading.value = false;
      }
    };
    img.onerror = () => {
      loading.value = false;
    };
    img.src = props.src;
  }
}

onMounted(() => {
  createIntersectionObserver();

  if (imageHtmlEl.value) {
    observer.observe(imageHtmlEl.value);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <div class="v-img">
    <img
      ref="imageHtmlEl"
      :alt="props.alt"
      :class="{ 'img-loading': loading }"
      class="img-el"
      :src="isVisible ? props.src : ''"
      loading="lazy"
    />
    <v-loader-ring
      v-if="loading && attrs.hasOwnProperty('loader')"
      class="loader-el"
    ></v-loader-ring>
  </div>
</template>

<style scoped>
.v-img {
  position: relative;
  overflow: hidden;
}

.img-el {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.img-loading {
  opacity: 0;
}

.loader-el {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
}
</style>
